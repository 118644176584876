.app-table {
    min-width: 700px !important;
    max-height: 700px !important;
    white-space: nowrap;
}

.app-table>thead {
    background-color: #132F4C !important;
    /* white-space: nowrap; */
}

.app-table>thead>tr>th {
    color: white !important;
    background-color: #132F4C !important;
    /* white-space: nowrap; */
}

.app-table>thead>tr>th>span {
    color: white !important;
    background-color: #132F4C !important;
    /* white-space: nowrap; */
}

.app-table>thead>tr>th>span>svg {
    color: white !important;
    background-color: #132F4C !important;
    /* white-space: nowrap; */
}

.app-table>tbody {
    background-color: white !important;
    /* white-space: nowrap; */
}

.app-table>tbody>tr>td {
    color: #132F4C !important;
    /* white-space: nowrap; */
    padding: 1.5px !important;
}

.app-table>tfoot>tr>td {
    font-size: small !important;
    color: #132F4C !important;
    padding: 1.5px !important;
    /* white-space: nowrap; */
    /* padding: 1px !important; */
}


/* .app-table-nowrap {
    min-width: 200px !important;
    max-height: 700px !important;
} */

.app-table-nowrap>thead {
    background-color: #132F4C !important;
}

.app-table-nowrap>thead>tr>th {
    color: white !important;
    background-color: #132F4C !important;
    white-space: nowrap;
}

.app-table-nowrap>thead>tr>th>span {
    color: white !important;
    background-color: #132F4C !important;
}

.app-table-nowrap>thead>tr>th>span>svg {
    color: white !important;
    background-color: #132F4C !important;
}

.app-table-nowrap>tbody {
    background-color: white !important;
}

.app-table-nowrap>tbody>tr>td {
    color: #132F4C !important;
    padding: 2px !important;
}

.app-table-nowrap>tfoot>tr>td {
    /* font-size: small !important; */
    color: #132F4C !important;
    padding: 2px !important;
}

/* 
.input-table {
    min-width: 50px !important;
    border: none !important;
} */

.icon-blue{
    color: #070942 !important;
}

.icon-red{
    color: red !important;
}

.icon-green{
    color: green !important;
}

.icon-orange{
    color: orange !important;
}

.icon-black{
    color: black !important;
}

.icon-gray{
    color: #E5E4E2 !important;
}

.icon-white{
    color: #FFFFFF !important;
}

.button-disabled{
    background-color: gray !important;
    color: white !important;
}

/* Tamaño del scroll */
::-webkit-scrollbar {
    width: 9px;     /* Tamaño del scroll en vertical */
    height: 9px;    /* Tamaño del scroll en horizontal */
    display: auto;  /*Ocultar scroll*/
}

/* Estilos barra (thumb) de scroll */
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}

::-webkit-scrollbar-track:hover, 
::-webkit-scrollbar-track:active {
    background: #d4d4d4;
}

/* 
.MuiInputBase-root .Mui-disabled{
    cursor: not-allowed !important;
} */

.powerbi-container {
    width: 100%;
    height: 750px !important;
}