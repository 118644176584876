
body, html {
  height: 100%;
}
body{
  background: url("../../assets/img/office.jpg") no-repeat center center;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

